import React, { useEffect } from "react"
import styled from "styled-components"
import ScrollTo from "react-scroll-into-view"
import smoothscroll from "smoothscroll-polyfill"
import { v4 as uuidv4 } from "uuid"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { useTranslation } from "react-i18next"

const Container = styled.div`
  max-width: 100rem;
  margin: 1rem auto;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    width: 100%;

    ${MEDIA_MIN_MEDIUM} {
      width: calc(33% - 2rem);
      margin: 0 1rem;
    }
  }

  ${MEDIA_MIN_MEDIUM} {
    margin: 3rem auto;
  }
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 0.2rem 0;
  margin: 1rem 0;
  background: ${(p) => (p.selected ? p.theme.colors.black : "none")};
  border: 0.1rem solid
    ${(p) => (p.selected ? "none" : p.theme.colors.backgroundDark)};
  color: ${(p) => (p.selected ? p.theme.colors.white : p.theme.colors.black)};
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: background 0.2s;

  ${MEDIA_MIN_MEDIUM} {
    height: 6rem;
    font-size: 1.4rem;

    &:hover {
      background: ${(p) => p.theme.colors.black};
      color: ${(p) => p.theme.colors.white};
      border: 0;
    }
  }
  p {
    max-width: 22rem;
  }

  i {
    font-size: 2rem;
    margin-right: 1.2rem;
    padding-top: 0.2rem;

    ${MEDIA_MIN_MEDIUM} {
      font-size: 2.4rem;
    }
  }
`

const SectionButtons = ({ sections, selectedSection, setSelectedSection }) => {
  const { t } = useTranslation("translations")
  const getSection = (section) => {
    switch (section) {
      // Order, delivery & Returns
      case "orderDeliveryReturns":
        return {
          icon: <i className="fal fa-shipping-fast"></i>,
          title: "Order, delivery & Returns"
        }
      // Product information
      case "productInformation":
        return {
          icon: <i className="fal fa-question-circle"></i>,
          title: "Product information"
        }
      // About us
      case "aboutUs":
        return {
          icon: <i class="fal fa-user-friends"></i>,
          title: "About us"
        }
      default:
        return {}
    }
  }

  useEffect(() => smoothscroll.polyfill(), [])

  if (!sections) return null

  return (
    <Container>
      {sections.map((section) => {
        const { icon, title } = getSection(section)

        return (
          <ScrollTo key={uuidv4()} selector={`.articles`}>
            <Section
              selected={section === selectedSection}
              onClick={() => {
                setSelectedSection(
                  section !== selectedSection ? section : selectedSection
                )
              }}
            >
              {icon}
              <p>{t(title)}</p>
            </Section>
          </ScrollTo>
        )
      })}
    </Container>
  )
}

export default SectionButtons
