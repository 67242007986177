import React from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../../constants"
import Article from "./Article"

const Container = styled.ul`
  max-width: ${containerSmallMaxWidth};
  margin: 2rem auto 6rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  position: relative;

  ${MEDIA_MIN_MEDIUM} {
    margin: 5rem auto 6rem;
  }
`

const Anchor = styled.div`
  position: absolute;
  top: -9rem;

  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }
`

const SectionArticles = ({ articles, selectedSection }) => {
  if (!articles) return null
  return (
    <Container>
      <Anchor className="articles" />
      {articles.map(
        (article) =>
          article.category === selectedSection && (
            <Article key={uuidv4()} article={article} />
          )
      )}
    </Container>
  )
}

export default SectionArticles
