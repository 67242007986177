import React, { useState } from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import { useTranslation } from "react-i18next"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../constants"
import Layout from "../components/Layout"
import Button from "../components/ui/Button"
import SectionButtons from "../components/faq/SectionButtons"
import SectionArticles from "../components/faq/SectionArticles"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import { graphql } from "gatsby"
import LineHeading from "../components/ui/LineHeading"

const Container = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto;
  padding: 2.5rem;
  text-align: center;
`

const Title = styled(LineHeading)`
  margin: 3rem;
  padding: 0 0 3rem 0.5rem;
  text-align: center;
  font-size: 3.2rem;
  line-height: 2.4rem;
  letter-spacing: 0.4rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3.5em;
  }
`

const Contact = styled.div`
  margin: 0 auto;
  padding: 2rem 0 6rem;
  text-align: center;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 0 8rem;
  }

  p {
    margin-top: 0.5rem;
  }
`

const ContactButton = styled(Button)`
  margin: 0 auto;
  width: auto;

  i {
    font-size: 2rem;
    margin-right: 1.2rem;
    padding-top: 0.2rem;

    ${MEDIA_MIN_MEDIUM} {
      font-size: 2.4rem;
    }
  }
`

const FaqPage = ({ data: { sanityFaqPage }, pageContext }) => {
  const { t } = useTranslation("translations")
  const [selectedSection, setSelectedSection] = useState("faq")
  const { title, faqTabItems } = sanityFaqPage
  const sections = [...new Set(faqTabItems?.map((item) => item.category))]

  console.log(sanityFaqPage)

  return (
    <Layout meta={{ title: title.en }} invertedHeader pageContext={pageContext}>
      <Breadcrumbs
        breadcrumbs={[
          {
            slug: "/faq/",
            name: title.en
          }
        ]}
      />
      <Container>
        <Title>{title.en}</Title>
        <SectionButtons
          sections={sections}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
        <SectionArticles
          articles={faqTabItems}
          selectedSection={selectedSection}
        />
        <Contact>
          <strong>{t("Can't find your question?")}</strong>
          <p>{t("Get in touch with us and we will help you!")}</p>
          <GatsbyLink to="/contact/">
            <ContactButton>
              <i className="fal fa-paper-plane"></i> {t("Contact us")}
            </ContactButton>
          </GatsbyLink>
        </Contact>
      </Container>
    </Layout>
  )
}

export default FaqPage

export const query = graphql`
  query {
    sanityFaqPage {
      title {
        en
        de
      }
      faqTabItems {
        title {
          en
          de
        }
        _rawText(resolveReferences: { maxDepth: 10 })
        category
        updatedAt: _updatedAt
      }
    }
  }
`
