import React, { useState, useContext } from "react"
import styled from "styled-components"
import dayjs from "dayjs"
import BlockContent from "@sanity/block-content-to-react"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"

const FaqArticle = styled.li`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
  margin: 0;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  > div {
    width: 100%;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 2.5rem 0;
  }

  &:last-of-type {
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  }
`

const Title = styled.h4`
  width: 100%;
  padding: 0 3rem 0 0;
  position: relative;
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  text-align: left;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.6rem;
  }

  i {
    font-size: 1.6rem;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -0.7rem;

    ${MEDIA_MIN_MEDIUM} {
      top: 0.2rem;
      font-size: 2rem;
      margin-top: 0;
    }
  }
`

const Body = styled.div`
  width: 100%;
  display: ${(p) => (p.open ? "block" : "none")};
`

const Answer = styled.div`
  padding: 1rem 2rem 2rem;
  margin: 2rem 0 1rem;
  width: 100%;
  background: ${(p) => p.theme.colors.background};
  text-align: left;

  ${MEDIA_MIN_MEDIUM} {
    padding: 1rem 3rem 3rem;
    margin: 2.5rem 0 0;
  }

  li {
    letter-spacing: 0.05rem;
  }

  a {
    color: ${(p) => p.theme.colors.primary};
  }

  div {
    width: 100%;
  }

  img {
    max-width: 100%;
  }
`

const UpdatedAt = styled.div`
  font-size: 1.3rem;
  color: #999;
`

const Article = ({ article }) => {
  const { t } = useTranslation("translations")
  const [expanded, setExpanded] = useState(null)
  const { languageCode } = useContext(BrinkContext)
  if (!article) return null
  const { title, _rawText } = article

  return (
    <FaqArticle>
      <Title
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        {title[languageCode] || title.en}
        {expanded ? (
          <i className="fal fa-minus"></i>
        ) : (
          <i className="fal fa-plus"></i>
        )}
      </Title>
      <Body open={expanded}>
        <Answer>
        <BlockContent blocks={ _rawText[languageCode]?.text || _rawText?.en?.text}/>
          <UpdatedAt>
            {t("Last updated on")}:{" "}
            {dayjs(article.createdAt).format("MMMM DD, YYYY")}
          </UpdatedAt>
        </Answer>
      </Body>
    </FaqArticle>
  )
}
export default Article
